import { mapGetters, mapActions } from 'vuex'
import parseData from '@/utils/parseData'
import languages from '@/utils/_languages.json'
import countries from '@/utils/_countries.json'

export default {
  name: 'ContentplanSettings',
  data () {
    return {
      isLoading: {
        contentplan: false
      },
      settings: [
        { label: 'Name', id: 'name', value: '', disabled: false },
        { label: 'Country for SERP analysis', id: 'country_crawl', value: '', options: [...countries], disabled: false },
        { label: 'Langugage for SERP analysis', id: 'language', value: '', options: [...languages], disabled: false },
        {
          label: 'Minimum SERP overlap for clustering',
          id: 'minimum_score',
          value: '',
          options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          disabled: false
        },
        { label: 'About info', id: 'about', value: '', disabled: false }
      ],

      integrationsAnalytics: [
        { label: 'Google Search Console', id: 'search_console', value: false, disabled: false, route: 'SearchConsoleIntegration' },
        { label: 'Google Analytics', id: 'google_analytics', value: false, disabled: false, route: 'GoogleAnalyticsIntegration' },
        { label: 'Google Ads (coming soon)', id: 'google_ads', value: false, disabled: true, route: '' }
      ],

      integrationsCMS: [
        { label: 'WordPress', id: 'wordpress', value: false, disabled: false, route: 'WordpressIntegration' },
        { label: 'Webflow (coming soon)', id: 'webflow', value: false, disabled: true, route: '' },
        { label: 'Notion (coming soon)', id: 'notion', value: false, disabled: true, route: '' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'activeContentplan',
      'activeIntegration',
      'apiUrl',
      'googleAnalyticsIntegration',
      'searchConsoleIntegration',
      'wordpressIntegration'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan ? this.activeContentplan.name : ''
    }
  },
  methods: {
    parseData,
    ...mapActions([
      'loadContentplan',
      'updateContentplan',
      'getIntegrations'
    ]),
    async loadData () {
      this.isLoading.contentplan = true
      document.title = 'Contentplan settings | ContentGecko'

      try {
        await this.getIntegrations({ contentplanId: this.contentplanId })
        const contentplanData = await this.loadContentplan({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId
        })
        this.mapSettings(contentplanData)
      } catch (error) {
        console.error('Error loading data:', error)
      }

      this.isLoading.contentplan = false
    },
    mapSettings (data) {
      this.settings.forEach(setting => {
        setting.value = data[setting.id]
      })
    },
    async saveSettings () {
      this.isLoading.contentplan = true

      try {
        const params = this.settings.reduce((acc, item) => {
          acc[item.id] = item.value
          return acc
        }, {})
        await this.updateContentplan({
          contentplanId: this.contentplanId,
          ...params
        })
        await this.loadContentplan({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId
        })
      } catch (error) {
        console.error(error)
      }

      this.isLoading.contentplan = false
    },
    async handleConnect (id) {
      let nextUrl = ''
      let scope = ''

      if (id === 'google_analytics') {
        nextUrl = window.location.href.replace('/settings', '/ga-integration')
        scope = 'all'
      }

      if (id === 'search_console') {
        nextUrl = window.location.href.replace('/settings', '/gsc-integration')
        scope = 'search-console'
      }

      const params = `next=${nextUrl}&contentplanId=${this.contentplanId}&scope=${scope}`
      const authUrl = window.location.origin + '/auth/in?' + params
      window.location.href = authUrl
    }
  },
  async mounted () {
    await this.loadData()
  }
}
