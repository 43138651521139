const jwt = require('jsonwebtoken')

const apiUrl = state => state.apiUrl

const websocketUrl = state => state.websocketUrl

const apiHeader = state => {
  return state.token ? { headers: { Authorization: `Bearer ${state.token}` } } : null
}

const user = (state) => {
  const token = state.token

  if (!token) return

  const user = jwt.decode(token)
  if (!user) return

  const issued = new Date(user.iat * 1000)
  const expires = new Date(user.exp * 1000)
  const now = new Date()

  if (issued > now || expires < now) {
    console.error('Invalid JWT dates')
    return
  }

  return {
    id: user.sub,
    name: user.name,
    expiring: new Date(user.exp * 1000)
  }
}

const showSidebar = state => state.showSidebar

const workspaces = state => state.workspaces

const activeWorkspace = state => state.activeWorkspace

const contentplans = state => state.contentplans

const activeContentplan = state => state.activeContentplan

const keywords = state => state.keywords

const crawl = state => state.crawl

const topics = state => state.topics

const articles = state => state.articles

const article = state => state.article

const sections = state => state.sections

const articleCounts = state => state.articleCounts

const articleUrls = state => state.articleUrls

const articleExternalUrls = state => state.articleExternalUrls

const score = state => state.score

const gsc = state => state.gsc

const pages = state => state.pages

const pageArticles = state => state.pageArticles

const pageKeywords = state => state.pageKeywords

const benchmarks = state => state.benchmarks

const internalLinks = state => state.internalLinks

const integrations = state => state.integrations

const activeIntegration = state => state.activeIntegration

const googleAnalyticsIntegration = state => {
  return state.integrations?.find(item => item.type === 'ga-4') ?? {}
}

const wordpressIntegration = state => {
  return state.integrations?.find(item => item.type === 'wp') ?? {}
}

const searchConsoleIntegration = state => {
  return state.integrations?.find(item => item.type === 'gsc') ?? {}
}

const snackbar = state => state.snackbar

export default {
  apiUrl,
  websocketUrl,
  apiHeader,
  user,
  showSidebar,
  workspaces,
  activeWorkspace,
  contentplans,
  activeContentplan,
  keywords,
  crawl,
  topics,
  articles,
  article,
  sections,
  articleCounts,
  articleUrls,
  articleExternalUrls,
  score,
  gsc,
  pages,
  pageArticles,
  pageKeywords,
  benchmarks,
  internalLinks,
  integrations,
  activeIntegration,
  googleAnalyticsIntegration,
  wordpressIntegration,
  searchConsoleIntegration,
  snackbar
}
