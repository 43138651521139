import * as d3 from 'd3'
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      isLoading: {
        resetKeywords: false,
        hierarchicalClustering: false,
        excludeKeywords: false,
        mergeCluster: false,
        similarClusters: false
      },
      selectedKeywords: [],

      tableArticleKeywords: {
        isLoading: false,
        headers: [
          { text: 'Keywords', value: 'keyword' },
          { text: 'Search Volume', value: 'estimated_search_volume' }
        ],
        sortBy: 'estimated_search_volume',
        descending: true
      },

      showNewArticleModal: false,
      showCreatedArticleModal: false,
      newArticleTitle: '',
      newArticleId: null,

      tabs: ['Remove from cluster', 'Add to cluster'],
      tabActive: 0,

      similarClusters: [],
      dendrogramData: null
    }
  },
  async mounted () {
    await this.loadData()
  },
  watch: {
    data: 'drawDendrogram'
  },
  computed: {
    ...mapGetters([
      'user',
      'activeWorkspace',
      'activeContentplan',
      'keywords'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    articleId () {
      return parseInt(this.$route.params.articleId)
    },
    selectedKeywordsCount () {
      return this.selectedKeywords?.length || 0
    }
  },
  methods: {
    ...mapActions([
      'updateKeywords',
      'addArticle',
      'postHierarchicalClustering',
      'loadArticlesByArticle',
      'loadArticleKeywords'
    ]),
    async loadData () {
      this.isLoading.hierarchicalClustering = true

      try {
        this.dendrogramData = await this.postHierarchicalClustering({
          articleId: this.articleId
        })
      } catch (error) {
        console.log(error)
      }

      this.isLoading.hierarchicalClustering = false
      this.drawDendrogram(this.dendrogramData.clusters)
    },
    drawDendrogram (data) {
      const vueInstance = this

      // If there's an existing SVG, remove it
      d3.select(this.$refs.dendrogram).select('svg').remove()

      const root = d3.hierarchy(data, function (d) {
        return d.isLeaf === false ? d.children : null
      }).sum(d => d.height)

      // Calculate height of tree graph
      const nodeSpacing = 12 // Define adequate spacing between each node
      const totalNodes = root.descendants().length
      const dynamicHeight = totalNodes * nodeSpacing

      const svg = d3.select(this.$refs.dendrogram).append('svg')
        .attr('width', 800)
        .attr('height', dynamicHeight)

      const treeLayout = d3.cluster().size([dynamicHeight - 20, 500]) // change tree to cluster
      treeLayout(root)

      const g = svg.append('g').attr('transform', 'translate(10,10)')

      g.selectAll('.link')
        .data(root.descendants().slice(1))
        .enter().append('path') // change path to line
        .attr('class', 'link')
        .attr('d', d => `
          M${d.y},${d.x}
          C${d.y},${(d.x + d.parent.x) / 2}
          ${d.parent.y},${(d.x + d.parent.x) / 2}
          ${d.parent.y},${d.parent.x}`)

      const node = g.selectAll('.node')
        .data(root.descendants())
        .enter().append('g')
        .attr('class', d => 'node' + (d.children ? ' node--internal' : ' node--leaf'))
        .attr('transform', d => `translate(${d.y},${d.x})`)

      node.append('circle')
        .attr('r', 5)
        .attr('fill', 'white')
        .attr('stroke', 'black')
        .attr('id', d => 'node-' + d.data.index) // set unique id for each node
        .on('click', function (event, d) {
          const isSelected = d3.select(this).attr('fill') === 'orange'

          if (isSelected) {
            // if node was selected, deselect it and its descendants
            d3.select(this).attr('fill', 'white')
            d.descendants().forEach(descendant => {
              if (descendant.data.keywordId && vueInstance.selectedKeywords.includes(descendant.data.keywordId)) { // Avoid undefined indices (e.g. for root)
                d3.select('#node-' + descendant.data.index).attr('fill', 'white')
                vueInstance.selectedKeywords = vueInstance.selectedKeywords.filter(keywordId => keywordId !== descendant.data.keywordId) // remove the id from selectedKeywords
              }
            })
          } else {
            // if node was not selected, select it and its descendants
            d3.select(this).attr('fill', 'orange')
            d.descendants().forEach(descendant => {
              if (descendant.data.keywordId && !vueInstance.selectedKeywords.includes(descendant.data.keywordId)) { // Avoid undefined indices (e.g. for root)
                d3.select('#node-' + descendant.data.index).attr('fill', 'orange')
                vueInstance.selectedKeywords.push(descendant.data.keywordId) // add the id to selectedKeywords
              }
            })
          }
        })

      node.append('text')
        .attr('dy', -6)
        .attr('x', 10)
        .attr('y', d => d.children ? -10 : 10)
        .style('text-anchor', d => d.children ? 'end' : 'start')
        .text(d => d.data.name)
    },
    async resetKeywords () {
      if (!this.selectedKeywordsCount) return
      this.isLoading.resetKeywords = true

      try {
        await this.updateKeywords({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          keywordIds: this.selectedKeywords,
          reset: true
        })
        await this.loadData()
        this.selectedKeywords = []
      } catch (error) {
        console.error(error)
      }

      this.isLoading.resetKeywords = false
    },
    async excludeKeywords () {
      if (!this.selectedKeywordsCount) return
      this.isLoading.excludeKeywords = true

      try {
        await this.updateKeywords({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          keywordIds: this.selectedKeywords,
          excluded: true
        })
        await this.loadData()
        this.selectedKeywords = []
      } catch (error) {
        console.error(error)
      }

      this.isLoading.excludeKeywords = false
    },
    async moveToNewCluster () {
      if (!this.selectedKeywordsCount) return
      this.isLoading.moveToNewCluster = true

      try {
        this.newArticleId = await this.addNewArticle(this.newArticleTitle)
        await this.moveSelectedKeywords(this.newArticleId, this.selectedKeywords)
        await this.loadData()
      } catch (error) {
        console.error(error)
      }

      this.selectedKeywords = []
      this.newArticleTitle = ''
      this.isLoading.moveToNewCluster = false
      this.showNewArticleModal = false
      this.showCreatedArticleModal = true
    },
    async addNewArticle (title) {
      if (!title) return

      let newArticleId
      try {
        const response = await this.addArticle({
          contentplanId: this.contentplanId,
          name: title
        })
        newArticleId = response.id
      } catch (e) {
        console.log(e)
      }

      return newArticleId
    },
    async moveSelectedKeywords (articleId, keywordIds) {
      if (!keywordIds || !articleId) return

      try {
        await this.updateKeywords({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          keywordIds,
          articleId
        })
      } catch (e) {
        console.log(e)
      }
    },
    closeCreatedArticleModal () {
      this.showCreatedArticleModal = false
      this.newArticleId = null
    },
    async modeSwitch (switchTo) {
      if (switchTo === 'Add to cluster' && !this.similarClusters.length) {
        await this.loadSimilarArticles()
      } else {
        // wait 100ms to allow the tab to switch before drawing the dendrogram
        await new Promise(resolve => setTimeout(resolve, 100))
        this.drawDendrogram(this.dendrogramData.clusters)
      }
    },
    async loadSimilarArticles () {
      this.isLoading.similarClusters = true
      const articles = await this.loadArticlesByArticle({
        contentplanId: this.contentplanId,
        articleId: this.articleId
      })

      // get keywords for each article
      const articlesWithKeywords = await Promise.all(articles.map(async article => {
        const keywords = await this.loadArticleKeywords({
          articleId: article.id,
          straightReturn: true
        })
        return {
          ...article,
          keywords
        }
      }))

      this.similarClusters = articlesWithKeywords
      this.isLoading.similarClusters = false
    },
    async mergeCluster (articleId) {
      this.isLoading.mergeCluster = articleId

      try {
        await this.updateKeywords({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          mergeFromArticleIds: [articleId],
          articleId: this.articleId
        })
        await Promise.all([
          this.loadArticleKeywords({
            articleId: this.articleId
          }),
          this.loadSimilarArticles()
        ])
      } catch (error) {
        console.log(error)
      }

      this.isLoading.mergeCluster = false
    }
  }
}
